# import RegulationFragment from "./fragments/Regulation.graphql"

mutation UpdateOneRegulation(
  $id: Int!,
  $isDraft: BoolFieldUpdateOperationsInput,
  $regulator: StringFieldUpdateOperationsInput,
  $description: NullableStringFieldUpdateOperationsInput,
  $obligationRegex: NullableStringFieldUpdateOperationsInput,
  $exceptions: NullableStringFieldUpdateOperationsInput,
  $source: StringFieldUpdateOperationsInput,
  $penalties: StringFieldUpdateOperationsInput,
  $piiDefinition: NullableStringFieldUpdateOperationsInput,
  $otherRequirements: NullableStringFieldUpdateOperationsInput,
  $legislationPassedAt: NullableDateTimeFieldUpdateOperationsInput,
  $effectiveAt: NullableDateTimeFieldUpdateOperationsInput,
  $legalType: EnumLegalTypeFieldUpdateOperationsInput,
  $typeName: String
){
updateOneRegulation(where: {id: $id}, data: {
    isDraft: $isDraft,
    regulator: $regulator
    description: $description
    obligationRegex: $obligationRegex
    exceptions: $exceptions
    source: $source
    penalties: $penalties
    piiDefinition: $piiDefinition
    otherRequirements: $otherRequirements
    legislationPassedAt: $legislationPassedAt
    effectiveAt: $effectiveAt
    legalType: $legalType
    type: {
      connect: {
        name: $typeName
      }
    }
  }) {
    ...RegulationFragment 
  }
}
