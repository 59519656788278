import React, {useEffect} from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {useUser} from "./UserContext";
import {useForm, FormProvider, Controller} from "react-hook-form";
import {contains, findWhere} from "underscore";

const MFA_METHODS = [
  {name: "SMS", value: "SMS_MFA"},
  {name: "Authenticator app", value: "SOFTWARE_TOKEN_MFA"},
];

const UserDetailsUpdateFields = () => {
  const {user, refetch, updateUserPhoneAndMFA, isUpdateLoading} = useUser();
  const userPhone = findWhere(user?.UserAttributes, {Name: "phone_number"})?.Value ?? "";
  const userMFA = contains(user?.UserMFASettingList, "SMS_MFA");
  const userPreferredMfaMethod = user?.PreferredMfaSetting || "SMS_MFA";
  const formMethods = useForm({
    defaultValues: {phone: userPhone, MFA: userMFA, preferredMfaMethod: userPreferredMfaMethod},
  });

  useEffect(() => {
    if (!user) return;
    formMethods.reset({
      phone: userPhone,
      MFA: userMFA,
      preferredMfaMethod: userPreferredMfaMethod,
    });
  }, [user, isUpdateLoading]);

  const handleAttributeUpdate = async (data) => {
    const hasPhoneChanged = data.phone !== userPhone;
    const hasMfaEnabledChanged = data.MFA !== userMFA;
    const hasMfaPreferenceChanged = data.preferredMfaMethod !== userPreferredMfaMethod;

    const dataToUpdate = {username: user.Username};
    if (hasPhoneChanged) dataToUpdate["phone"] = data.phone;
    if (hasMfaEnabledChanged || hasMfaPreferenceChanged) dataToUpdate["enableMfa"] = data.MFA;
    if (hasMfaPreferenceChanged) dataToUpdate["preferredMfaMethod"] = data.preferredMfaMethod;

    if (hasPhoneChanged || hasMfaEnabledChanged || hasMfaPreferenceChanged) {
      await updateUserPhoneAndMFA(dataToUpdate);
      await refetch();
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{paddingLeft: 1, marginTop: 2}}>
        Attributes
      </Typography>
      <Typography variant="caption" sx={{paddingLeft: 1}}>
        Edits affect shared pool attributes only
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleAttributeUpdate)}>
          <Box padding={1} mt={1}>
            <TextField
              {...formMethods.register("phone")}
              variant="outlined"
              id="phone"
              label="Phone"
              size="small"
              InputLabelProps={{shrink: true}}
              disabled={isUpdateLoading}
            />
          </Box>
          <Box padding={1}>
            <Controller
              name="MFA"
              render={({field: {value, onChange}}) => (
                <FormControlLabel
                  control={
                    <Switch checked={value} onChange={(event) => onChange(event.target.checked)} />
                  }
                  label="MFA Enabled"
                />
              )}
            />
          </Box>
          <Box padding={1}>
            <Controller
              name="preferredMfaMethod"
              render={({field: {value, onChange}}) => (
                <FormControl sx={{minWidth: 200}}>
                  <InputLabel id="mfa-method-label">MFA Method</InputLabel>
                  <Select
                    name="preferredMfaMethod"
                    label="MFA Method"
                    value={value}
                    autoWidth
                    onChange={(event) => onChange(event.target.value || userPreferredMfaMethod)}
                  >
                    {MFA_METHODS.map((mfaMethod) => (
                      <MenuItem key={mfaMethod.value} value={mfaMethod.value}>
                        {mfaMethod.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            ></Controller>
          </Box>
          <Box padding={1}>
            <Button type="submit" variant="outlined">
              Update
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default UserDetailsUpdateFields;
