/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { objectKeys } from "./tools/objectKeys";
export function mergeClasses(classesFromUseStyles, classesFromProps, cx) {
    //NOTE: We use this test to be resilient in case classesFromProps is not of the expected type.
    if (!(classesFromProps instanceof Object)) {
        return classesFromUseStyles;
    }
    const out = {};
    objectKeys(classesFromUseStyles).forEach(ruleName => (out[ruleName] = cx(classesFromUseStyles[ruleName], classesFromProps[ruleName])));
    objectKeys(classesFromProps).forEach(ruleName => {
        if (ruleName in classesFromUseStyles) {
            return;
        }
        const className = classesFromProps[ruleName];
        //...Same here, that why we don't do className === undefined
        if (typeof className !== "string") {
            return;
        }
        out[ruleName] = className;
    });
    return out;
}
